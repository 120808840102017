body {
  font-family: 'DM Mono', Lato, LatoExtended, sans-serif !important;
  background-color: "#FEFECF",
}

p, h1, h2, h3, h4, h5 {
  font-family: 'pkmn', 'DM Mono', Lato, LatoExtended, sans-serif !important;
}

h6 {
  font-family: 'DM Mono', Lato, LatoExtended, sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}